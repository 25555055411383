import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Artwork } from "./models/Artwork";
import type { RootState } from "./store";

export const huezApi = createApi({
    reducerPath: "huezApi",
    // TODO: move URL to env
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.staging.huez.app/",
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken;

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set("authorization", `bearer ${token}`);
            }

            return headers;
        },
    }),
    // TODO: Split to files
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: "auth/login",
                method: "POST",
                body: payload,
            }),
        }),
        register: builder.mutation({
            query: (payload) => ({
                url: "auth/register",
                method: "POST",
                body: payload,
            }),
        }),
        getArtworks: builder.query<{ items: Artwork[]; count: number }, {}>({
            query: () => ({ url: `artworks/artist` }),
        }),
    }),
});

export const { useLoginMutation, useGetArtworksQuery } = huezApi;
