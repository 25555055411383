import React from "react";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { LucideIcon } from "lucide-react";

function NavItem(props: { icon: LucideIcon; label: string; route: string }) {
    return (
        <Nav.Item>
            <LinkContainer to={props.route}>
                <Nav.Link>
                    <props.icon size={25} strokeWidth={1} />
                    <span className="d-none d-md-inline mx-3">
                        {props.label}
                    </span>
                </Nav.Link>
            </LinkContainer>
        </Nav.Item>
    );
}

export default NavItem;
