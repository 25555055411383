import React from "react";
import { User } from "../../../profile/User";

function ProfileSummary({ user }: { user: User }) {
    return (
        <div className="d-flex mt-3 mb-4">
            <div style={{ width: "120px" }}>
                <img
                    src="https://avatar.iran.liara.run/public/50"
                    alt="John"
                    width={100}
                    height={100}
                    className="d-inline"
                />
            </div>
            <div>
                <h1 className="mt-3 mb-1 fw-light">
                    Welcome back, {user.firstName || user.email}!
                </h1>
                <p className="fw-light text-secondary">
                    Member since 14 June 2023
                </p>
            </div>
        </div>
    );
}

export default ProfileSummary;
