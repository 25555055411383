import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Bell, Moon, Sun } from "lucide-react";
import MainNav from "./MainNav";

import "./Root.scss";
import { useAppSelector } from "../../hooks";

import { ReactComponent as LightModeLogo } from "../../assets/images/logo-studio-light-mode.svg";
import { ReactComponent as DarkModeLogo } from "../../assets/images/logo-studio-dark-mode.svg";

function Root() {
    useEffect(() => {
        setSystemMode();
    }, []);

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    const [mode, setMode] = useState<"dark" | "light">("light");

    const setSystemMode = () => {
        if (
            window &&
            window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
            setMode("dark");
        } else {
            setMode("light");
        }

        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (event) => {
                setMode(event.matches ? "dark" : "light");
            });
    };

    const toggleMode = () => {
        if (mode === "dark") {
            setMode("light");
        } else {
            setMode("dark");
        }

        // TODO: Store state for future
    };

    return (
        <Container
            fluid
            className="m-0 p-0 d-flex flex-column min-vh-100"
            data-bs-theme={mode}
        >
            {isLoggedIn ? (
                <Navbar expand="lg" className={`sticky-top bg-primary-subtle`}>
                    <Container fluid>
                        <Navbar.Brand style={{ height: 40, width: 100 }}>
                            {mode === "dark" ? (
                                <DarkModeLogo />
                            ) : (
                                <LightModeLogo />
                            )}
                        </Navbar.Brand>
                        <Nav className="flex-row">
                            <Nav.Link onClick={toggleMode}>
                                {mode === "dark" ? (
                                    <Sun size={20} className="mx-2" />
                                ) : (
                                    <Moon size={20} className="mx-2" />
                                )}
                            </Nav.Link>
                            <Nav.Link>
                                <Bell size={20} className="mx-2" />
                            </Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            ) : (
                <></>
            )}

            <div
                className={`d-flex flex-fill flex-column-reverse flex-lg-row bg-${mode}-subtle text-bg-${mode}`}
            >
                {isLoggedIn ? (
                    <div
                        className={`m-3 p-3 sticky-bottom sticky-lg-top flex-shrink-0 rounded bg-primary-subtle`}
                        id="main-nav"
                    >
                        <MainNav />
                    </div>
                ) : (
                    <></>
                )}
                <div className="flex-fill">
                    <Outlet />
                </div>
            </div>
        </Container>
    );
}

export default Root;
