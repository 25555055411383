import React from "react";
import { Form, FormControlProps } from "react-bootstrap";

interface InputFieldProps extends FormControlProps {
    invalidFeedbackMessage?: string;
    helpText?: string;
    label?: string;
}

function InputField(props: InputFieldProps) {
    return (
        <Form.Group className="d-block mb-3">
            {props.label ? <Form.Label>{props.label}</Form.Label> : null}
            <Form.Control {...props}></Form.Control>
            <Form.Text muted>{props.helpText}</Form.Text>
            <Form.Control.Feedback type="invalid">
                {props.invalidFeedbackMessage}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default InputField;
