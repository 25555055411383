import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function RegisterUploadFirstArtwork() {
    const navigate = useNavigate();

    return (
        <>
            <div className="row g-3">
                <span className="col-12 col-md d-grid">
                    <Button
                        className="rounded-5"
                        variant="primary"
                        onClick={() => navigate("/artwork")}
                    >
                        Upload now
                    </Button>
                </span>
                <span className="col-12 col-md d-grid">
                    <Button
                        className="rounded-5"
                        variant="secondary"
                        onClick={() => navigate("/")}
                    >
                        Upload later
                    </Button>
                </span>
            </div>
        </>
    );
}

export default RegisterUploadFirstArtwork;
