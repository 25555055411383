import React from "react";
import { ProgressBar } from "react-bootstrap";

function WizardSteps({ activeStep }: { activeStep: number }) {
    const steps = [
        {
            title: "Login information",
            description: "Choose how to login to your account",
        },
        {
            title: "Artist profile",
            description: "Setup how people will see your profile",
        },
        {
            title: "Welcome to Huez!",
            description: "Upload your first artwork and start selling ",
        },
    ];
    return (
        <div className="mb-4">
            <ProgressBar now={(activeStep - 1) * 50} style={{ height: 5 }} />

            <div
                className="d-flex justify-content-between mb-4"
                style={{ marginTop: -20 }}
            >
                {steps.map((step, i) => {
                    return (
                        <div
                            className={
                                "d-inline-block p-2 text-white rounded-circle " +
                                (i < activeStep ? "bg-primary" : "bg-secondary")
                            }
                            style={{
                                width: 40,
                                height: 40,
                                textAlign: "center",
                            }}
                        >
                            {i + 1}
                        </div>
                    );
                })}
            </div>
            <h2>{steps[activeStep - 1].title}</h2>
            <p>{steps[activeStep - 1].description}</p>
        </div>
    );
}

export default WizardSteps;
