import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputField from "../../components/atoms/InputField/InputField";
import WizardStepProps from "./WizardStepsProps";

function RegisterCreateAccount(props: WizardStepProps) {
    const [email, setEmail] = useState<string>("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    const [password, setPassword] = useState<string>("");
    const [isValidPassword, setIsValidPassword] = useState(true);

    const [firstName, setFirstName] = useState<string>("");

    const [lastName, setLastName] = useState<string>("");

    const createAccount = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsValidEmail(true);
        setIsValidPassword(true);
        props.onComplete();
    };

    return (
        <>
            <Form noValidate onSubmit={createAccount}>
                <InputField
                    required
                    isInvalid={!isValidEmail}
                    type="email"
                    placeholder="Email"
                    value={email}
                    invalidFeedbackMessage="Please enter a valid email"
                    onChange={(e) => setEmail(e.target.value)}
                ></InputField>

                <InputField
                    required
                    isInvalid={!isValidPassword}
                    type="password"
                    placeholder="Password"
                    value={password}
                    invalidFeedbackMessage="Please enter a valid password"
                    onChange={(e) => setPassword(e.target.value)}
                ></InputField>
                <p>Tell us more about yourself</p>
                <InputField
                    required
                    type="text"
                    placeholder="First name"
                    value={firstName}
                    invalidFeedbackMessage="First name is required"
                    onChange={(e) => setFirstName(e.target.value)}
                ></InputField>
                <InputField
                    required
                    type="text"
                    placeholder="Last name"
                    value={lastName}
                    invalidFeedbackMessage="Last name is required"
                    onChange={(e) => setLastName(e.target.value)}
                ></InputField>

                <div className="d-grid">
                    <Button
                        className="rounded-5"
                        variant="primary"
                        type="submit"
                    >
                        Create account
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default RegisterCreateAccount;
