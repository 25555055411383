import React from "react";
import { Badge, Card } from "react-bootstrap";

function ArtworkRow({ artwork }: { artwork: any }) {
    return (
        <Card className="p-2 mb-3" style={{ maxWidth: 800 }}>
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <img
                        src={
                            artwork.images.find((image: any) => image.isMain)
                                ?.url
                        }
                        className="d-inline float-start img-thumbnail"
                        style={{ height: 90, width: 90 }}
                        alt={artwork.name}
                    />

                    <Badge bg="success mt-1 d-inline d-md-none">
                        {artwork.status}
                    </Badge>
                </div>
                <div>
                    <Card.Body>
                        <Card.Title>{artwork.name} test test test</Card.Title>
                        <Card.Text className="d-none d-md-block">
                            {artwork.artStyles.map((style: any) => (
                                <Badge
                                    pill
                                    bg="light"
                                    className="text-dark fw-normal me-2"
                                >
                                    {style.name}
                                </Badge>
                            ))}
                        </Card.Text>
                    </Card.Body>
                </div>
                <Badge
                    bg="success d-none d-md-block"
                    style={{ position: "absolute", top: 10, right: 10 }}
                >
                    {artwork.status}
                </Badge>
                <a
                    href="/#"
                    className="stretched-link"
                    style={{ textDecoration: "none" }}
                    onClick={(e) => e.preventDefault()}
                >
                    &nbsp;
                </a>
            </div>
        </Card>
    );
}

export default ArtworkRow;
