import React, { useState } from "react";
import PageTitle from "../../components/atoms/PageTitle/PageTitle";
import InputField from "../../components/atoms/InputField/InputField";
import { Button, Card, Container, Form } from "react-bootstrap";
import SectionTitle from "../../components/atoms/SectionTitle/SectionTitle";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the image preview plugin
registerPlugin(FilePondPluginImagePreview);

function AddArtwork() {
    const navigate = useNavigate();

    const [filesList, setFilesList] = useState<any>([]);
    return (
        <Container>
            <PageTitle title="Add artwork" />

            <SectionTitle title="Details" />
            <Card>
                <Card.Body>
                    <Form>
                        <InputField label="Title" />
                        <InputField label="Styles" />
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={5} />
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>

            <SectionTitle title="Images" />
            <Card className="mt-3">
                <Card.Body>
                    <FilePond
                        allowMultiple={true}
                        allowImagePreview={true}
                        credits={false}
                        acceptedFileTypes={["image/*"]}
                        onupdatefiles={(files) => setFilesList(files)}
                    />

                    <Form.Select
                        disabled={filesList.length === 0}
                        aria-label="Default select example"
                    >
                        {filesList.map((file: any) => (
                            <option value={file.id}>{file.filename}</option>
                        ))}
                    </Form.Select>
                </Card.Body>
            </Card>

            <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                <Button
                    variant="secondary"
                    onClick={() => navigate("/artwork")}
                >
                    <ArrowLeft strokeWidth={1} /> Back to List
                </Button>
                <Button>Submit</Button>
            </div>
        </Container>
    );
}

export default AddArtwork;
