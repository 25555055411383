import React from "react";
import Nav from "react-bootstrap/Nav";
import NavItem from "./NavItem";
import {
    CircleUser,
    LayoutDashboard,
    ShoppingBag,
    Palette,
} from "lucide-react";

function MainNav() {
    return (
        <Nav
            variant="pills"
            className="flex-lg-column justify-content-between justify-content-lg-start"
        >
            <NavItem icon={LayoutDashboard} route="/" label="Dashboard" />
            <NavItem icon={ShoppingBag} route="/orders" label="Orders" />
            <NavItem icon={Palette} route="/artwork" label="My Artwork" />
            <NavItem icon={CircleUser} route="/profile" label="Profile" />
        </Nav>
    );
}

export default MainNav;
