import React from "react";
import { Card } from "react-bootstrap";
import { ArrowUpCircle, ArrowDownCircle } from "lucide-react";
import Chart from "react-apexcharts";
import { QuickInsight } from "./QuickInsight";

/**
 * A card component for showing quick insights in dashboards
 */
function QuickInsightCard(props: QuickInsight) {
    const chartOptions = {
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        colors: [props.chartColor],
        tooltip: {
            fixed: {
                enabled: false,
            },
            x: {
                show: false,
            },
            y: {
                title: {
                    formatter: function () {
                        return "";
                    },
                },
            },
            marker: {
                show: false,
            },
        },
    };
    return (
        <Card className="flex-grow-1 p-2" {...props}>
            <Card.Body className="d-flex justify-content-between align-items-center">
                <span>
                    <Card.Title className="fw-light">{props.title}</Card.Title>
                    <p className="m-0">
                        <span className="fs-4 fw-bold">{props.value}</span>
                        <span className="ms-3">
                            {props.trendPercent > 0 ? (
                                <ArrowUpCircle size={18} color="green" />
                            ) : (
                                <ArrowDownCircle size={18} color="red" />
                            )}
                        </span>
                        <span className="ms-2">{props.trendPercent} %</span>
                    </p>
                    <p className="m-0 fw-light text-body-secondary">
                        Compared to last week
                    </p>
                </span>
                <span className="text-dark">
                    <Chart
                        options={chartOptions}
                        series={[{ data: props.data }]}
                        type={props.chartType}
                        width={100}
                        height={50}
                    />
                </span>
            </Card.Body>
        </Card>
    );
}

export default QuickInsightCard;
