import React from "react";
import { Button, ButtonProps } from "react-bootstrap";

import { ReactComponent as AppleLogo } from "../../../assets/images/apple.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/images/facebook.svg";
import { ReactComponent as GoogleLogo } from "../../../assets/images/google.svg";
import { ReactComponentLike } from "prop-types";

interface SocialButtonProps extends ButtonProps {
    platform: "apple" | "facebook" | "google";
}

function SocialButton(props: SocialButtonProps) {
    let Logo: ReactComponentLike = GoogleLogo;

    if (props.platform === "apple") {
        Logo = AppleLogo;
    } else if (props.platform === "facebook") {
        Logo = FacebookLogo;
    }
    return (
        <Button
            className="rounded-5 d-flex align-items-center justify-content-center"
            variant="outline-dark"
            type="submit"
        >
            <Logo className="me-2" width={24} height={24} />
            {props.platform.charAt(0).toUpperCase() + props.platform.slice(1)}
        </Button>
    );
}

export default SocialButton;
