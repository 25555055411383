import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { Key, PlusSquare } from "lucide-react";
import SectionTitle from "../../../../components/atoms/SectionTitle/SectionTitle";

function QuickActions() {
    const actions = [
        {
            icon: PlusSquare,
            title: "Add new artwork",
        },
        {
            icon: Key,
            title: "Update Password",
        },
    ];

    return (
        <>
            <SectionTitle title="Quick Actions"></SectionTitle>
            <Card>
                <ListGroup>
                    {actions.map((action) => {
                        return (
                            <ListGroup.Item action className="p-3">
                                <action.icon strokeWidth={1} />
                                <span className="ps-3 fw-light">
                                    {action.title}
                                </span>
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </Card>
        </>
    );
}

export default QuickActions;
