import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setCredentials } from "./AuthSlice";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { ReactComponent as HuezStudioLogo } from "../../assets/images/logo-studio-app.svg";
import { useLoginMutation } from "../../api";
import InputField from "../../components/atoms/InputField/InputField";
import { LinkContainer } from "react-router-bootstrap";
import SocialButton from "../../components/atoms/SocialButton/SocialButton";

function Login() {
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    });

    const [email, setEmail] = useState<string>("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    const [password, setPassword] = useState<string>("");
    const [isValidPassword, setIsValidPassword] = useState(true);

    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [login, result] = useLoginMutation();

    const validateEmail = () => {
        if (email && EmailValidator.validate(email)) {
            setIsValidEmail(true);
            return true;
        }

        setIsValidEmail(false);
        return false;
    };

    const validatePassword = () => {
        if (password) {
            setIsValidPassword(true);
            return true;
        }

        setIsValidPassword(false);
        return false;
    };

    const onLoginSubmit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (validateEmail() && validatePassword()) {
            try {
                const response = await login({ email, password }).unwrap();
                dispatch(setCredentials(response));
                navigate("/");
            } catch (error: any) {
                if (error && error.status && error.status === 401) {
                    setErrorMessage("Invalid email or password!");
                } else {
                    setErrorMessage(
                        "An unknown error occurred. Please try again later",
                    );
                }
            }
        }
    };

    return (
        <Container
            fluid
            className="huez-bg-gradient min-vh-100 mx-0 p-0 d-flex align-items-end align-items-md-center justify-content-center justify-content-end"
        >
            <Card
                className="flex-fill shadow px-2 py-5 mx-3 text-center text-dark"
                style={{ maxWidth: "550px" }}
                bg="light"
                border="light"
                data-bs-theme="light"
            >
                <Card.Body>
                    <Card.Title>
                        <HuezStudioLogo width={100} className="mb-4" />
                    </Card.Title>

                    {result.isError ? (
                        <Alert variant="danger">{errorMessage}</Alert>
                    ) : null}
                    <Form noValidate onSubmit={onLoginSubmit}>
                        <InputField
                            required
                            isInvalid={!isValidEmail}
                            type="email"
                            placeholder="Email"
                            value={email}
                            invalidFeedbackMessage="Please enter a valid email"
                            onChange={(e) => setEmail(e.target.value)}
                        ></InputField>

                        <InputField
                            required
                            isInvalid={!isValidPassword}
                            type="password"
                            placeholder="Password"
                            value={password}
                            invalidFeedbackMessage="Please enter a valid password"
                            onChange={(e) => setPassword(e.target.value)}
                        ></InputField>

                        <div className="d-grid">
                            <Button
                                className="rounded-5"
                                variant="primary"
                                type="submit"
                                disabled={result.isLoading}
                            >
                                {result.isLoading ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    "Login"
                                )}
                            </Button>
                        </div>
                    </Form>

                    <div className="d-grid"></div>
                    <p className="my-3">Or continue with</p>
                    <div className="row g-3">
                        <span className="col-12 col-md d-grid">
                            <SocialButton platform="google" />
                        </span>
                        <span className="col-12 col-md d-grid">
                            <SocialButton platform="apple" />
                        </span>
                    </div>
                </Card.Body>
                <Card.Body>
                    <p>
                        Don't have an account? &nbsp;
                        <LinkContainer to="/register">
                            <a href="/register">Register now!</a>
                        </LinkContainer>
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Login;
