import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {
    RouterProvider,
    createBrowserRouter,
    redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import Root from "./pages/root/Root";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashoboard/Dashboard";
import OrdersList from "./pages/orders/OrdersList";
import ArtworkList from "./pages/artwork/ArtworkList";
import Profile from "./pages/profile/Profile";
import ErrorPage from "./pages/error/ErrorPage";
import { store } from "./store";
import RegisterPage from "./pages/register/RegisterPage";
import AddArtwork from "./pages/artwork/AddArtwork";

const ensureLoggedInUser = () => {
    const isLoggedIn = store.getState().auth.isLoggedIn;
    if (!isLoggedIn) {
        return redirect("/login");
    }

    return null;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Dashboard />,
                loader: async () => {
                    return ensureLoggedInUser();
                },
            },
            {
                path: "orders",
                element: <OrdersList />,
                loader: async () => {
                    return ensureLoggedInUser();
                },
            },
            {
                path: "artwork",
                loader: async () => {
                    return ensureLoggedInUser();
                },
                children: [
                    { path: "", element: <ArtworkList /> },
                    { path: "new", element: <AddArtwork /> },
                ],
            },
            {
                path: "profile",
                element: <Profile />,
                loader: async () => {
                    return ensureLoggedInUser();
                },
            },
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "register",
                element: <RegisterPage />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
