import React, { useState } from "react";
import PageTitle from "../../components/atoms/PageTitle/PageTitle";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { LayoutGrid, LayoutList, Plus } from "lucide-react";
import ArtworkCard from "./components/ArtworkCard";
import ArtworkRow from "./components/ArtworkRow";
import { useNavigate } from "react-router-dom";
import { useGetArtworksQuery } from "../../api";

function ArtworkList() {
    const [viewAs, setViewAs] = useState<"grid" | "list">("grid");

    const { data: artworks, error, isLoading } = useGetArtworksQuery({});

    const navigate = useNavigate();

    return (
        <Container fluid>
            <PageTitle title="My artwork"></PageTitle>

            <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                <Button onClick={() => navigate("/artwork/new")}>
                    <Plus strokeWidth={1} /> Add artwork
                </Button>

                <ButtonToolbar>
                    <ButtonGroup className="me-2" aria-label="View options">
                        <Button
                            className={viewAs === "grid" ? "active" : ""}
                            onClick={() => setViewAs("grid")}
                            variant="outline-secondary"
                        >
                            <LayoutGrid strokeWidth={1} />
                        </Button>
                        <Button
                            className={viewAs === "list" ? "active" : ""}
                            onClick={() => setViewAs("list")}
                            variant="outline-secondary"
                        >
                            <LayoutList strokeWidth={1} />
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>

            {error ? (
                <>Oh no, there was an error!</>
            ) : isLoading ? (
                <>Loading...</>
            ) : artworks ? (
                <>
                    {artworks?.items.length === 0 ? (
                        <p>You don't have any artworks. Create your first!</p>
                    ) : null}
                    {viewAs === "grid" ? (
                        <Row className="row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xxl-6 g-4">
                            {artworks?.items.map((artwork) => (
                                <Col>
                                    <ArtworkCard artwork={artwork} />
                                </Col>
                            ))}
                        </Row>
                    ) : null}

                    {viewAs === "list" ? (
                        <div>
                            {artworks?.items.map((artwork) => (
                                <ArtworkRow artwork={artwork} />
                            ))}
                        </div>
                    ) : null}
                </>
            ) : (
                <>No data</>
            )}
        </Container>
    );
}

export default ArtworkList;
