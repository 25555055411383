import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuickInsights from "./components/QuickInsights/QuickInsights";
import ProfileSummary from "./components/ProfileSummary/ProfileSummary";
import LatestOrdersList from "./components/LatestOrders/LatestOrdersList";
import QuickActions from "./components/QuickActions/QuickActions";
import { QuickInsight } from "./components/QuickInsights/QuickInsight";
import { useAppSelector } from "../../hooks";

function Dashboard() {
    const loggedInUser = useAppSelector((state) => state.auth.loggedInUser);
    const quickInsights: QuickInsight[] = [
        {
            title: "Sales",
            value: "25,000 $",
            trendPercent: 20,
            chartType: "area",
            chartColor: "#ffa300",
            data: [1, 3, 5, 2, 4, 7, 6],
        },
        {
            title: "Orders",
            value: "15",
            trendPercent: -13,
            chartType: "bar",
            chartColor: "#9b19f5",
            data: [7, 3, 5, 1, 4, 3, 6],
        },
        {
            title: "Artwork views",
            value: "3,564",
            trendPercent: 60,
            chartType: "bar",
            chartColor: "#00bfa0",
            data: [1200, 1750, 1427, 2633, 2525, 3112, 3564],
        },
    ];
    return (
        <Container fluid>
            <ProfileSummary user={loggedInUser}></ProfileSummary>

            <QuickInsights insights={quickInsights}></QuickInsights>

            <Row>
                <Col className="mt-4" xs={12} lg={8}>
                    <LatestOrdersList></LatestOrdersList>
                </Col>
                <Col className="mt-4" xs={12} lg={4}>
                    <QuickActions></QuickActions>
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;
