import React from "react";
import { NavLink } from "react-router-dom";
import DataTable from "../../../../components/DataTable/DataTable";
import SectionTitle from "../../../../components/atoms/SectionTitle/SectionTitle";

function LatestOrdersList() {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <SectionTitle title="Latest Orders"></SectionTitle>
                <NavLink to={"/orders"}>
                    <span className="ms-3">Show all orders</span>
                </NavLink>
            </div>

            <DataTable></DataTable>
        </>
    );
}

export default LatestOrdersList;
