import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearCredentials } from "../login/AuthSlice";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/atoms/PageTitle/PageTitle";
import SectionTitle from "../../components/atoms/SectionTitle/SectionTitle";
import { User } from "./User";
import InputField from "../../components/atoms/InputField/InputField";

function Profile() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user: User = useAppSelector((state) => state.auth.loggedInUser);

    const onLogoutClick = () => {
        dispatch(clearCredentials());
        navigate("/login");
    };

    return (
        <Container>
            <PageTitle title="Profile"></PageTitle>
            <img
                src="https://avatar.iran.liara.run/public/50"
                alt="John"
                width={100}
                height={100}
                className=""
            />

            <SectionTitle title="Account information"></SectionTitle>
            <Card>
                <Card.Body>
                    <InputField
                        required
                        type="text"
                        placeholder="John"
                        label="First name"
                        value={user.firstName}
                    ></InputField>
                    <InputField
                        required
                        type="text"
                        placeholder="Doe"
                        label="Last name"
                        value={user.lastName}
                    ></InputField>
                    <InputField
                        required
                        type="text"
                        placeholder="email@example.com"
                        label="Email"
                        value={user.email}
                    ></InputField>

                    <Button>Save</Button>
                </Card.Body>
            </Card>

            <SectionTitle title="Artist profile"></SectionTitle>
            <Card>
                <Card.Body>
                    <InputField
                        required
                        type="text"
                        placeholder="John Doe"
                        label="Display name"
                    ></InputField>
                    <InputField
                        required
                        type="text"
                        placeholder="www.example.com"
                        label="Website URL"
                    ></InputField>
                    <Form.Group>
                        <Form.Label>Bio</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>

                    <Button className="mt-3">Save</Button>
                </Card.Body>
            </Card>

            <SectionTitle title="Password"></SectionTitle>
            <Card>
                <Card.Body>
                    <InputField
                        required
                        type="password"
                        placeholder="Password"
                        label="New Password"
                    ></InputField>
                    <InputField
                        required
                        type="password"
                        placeholder="Password"
                        label="Confirm new password"
                    ></InputField>

                    <Button>Update password</Button>
                </Card.Body>
            </Card>

            <Button className="my-5" variant="danger" onClick={onLogoutClick}>
                Logout
            </Button>
        </Container>
    );
}

export default Profile;
