import React from "react";
import QuickInsightCard from "./QuickInsightCard";
import { Carousel, Row, Col } from "react-bootstrap";
import { QuickInsight } from "./QuickInsight";

import "./QuickInsights.scss";
import SectionTitle from "../../../../components/atoms/SectionTitle/SectionTitle";

interface QuickInsightsProps {
    insights: QuickInsight[];
}

function QuickInsights(props: QuickInsightsProps) {
    const buildQuickInsightCard = (insight: QuickInsight) => {
        return (
            <QuickInsightCard
                title={insight.title}
                value={insight.value}
                trendPercent={insight.trendPercent}
                chartType={insight.chartType}
                chartColor={insight.chartColor}
                data={insight.data}
            ></QuickInsightCard>
        );
    };
    return (
        <>
            <SectionTitle title="Quick Insights"></SectionTitle>
            {/* For small screens (phones) */}
            <Carousel
                className="d-block d-md-none mb-4"
                interval={null}
                wrap={false}
            >
                {props.insights.map((insight) => (
                    <Carousel.Item>
                        {buildQuickInsightCard(insight)}
                    </Carousel.Item>
                ))}
            </Carousel>

            {/* For large screens */}
            <Row className="d-none d-md-flex">
                {props.insights.map((insight) => (
                    <Col md={6} lg={4} className="mb-4">
                        {buildQuickInsightCard(insight)}
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default QuickInsights;
