import React, { useEffect, useState } from "react";
import RegisterCreateAccount from "./RegisterCreateAccount";
import RegisterSetupProfile from "./RegisterSetupProfile";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RegisterUploadFirstArtwork from "./RegisterUploadFirstArtwork";
import WizardSteps from "./WizardSteps";
import { LinkContainer } from "react-router-bootstrap";
import { useAppSelector } from "../../hooks";
import { ReactComponent as HuezStudioLogo } from "../../assets/images/logo-studio-app.svg";

function RegisterPage() {
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    });

    const navigate = useNavigate();

    const [step, setStep] = useState<number>(1);

    const moveToNextStep = () => {
        if (step >= 3) {
            navigate("/");
        } else {
            setStep(step + 1);
        }
    };

    return (
        <Container
            fluid
            className="huez-bg-gradient min-vh-100 mx-0 p-0 d-flex align-items-end align-items-md-center justify-content-center text-center"
        >
            <Card
                className="flex-fill shadow px-3 py-5 m-3 text-dark"
                style={{ maxWidth: "550px" }}
                bg="light"
                border="light"
                data-bs-theme="light"
            >
                <Card.Title>
                    <HuezStudioLogo width={100} className="mb-4" />
                </Card.Title>
                <Card.Body>
                    <h1>Create new account</h1>
                </Card.Body>
                <Card.Body>
                    <WizardSteps activeStep={step}></WizardSteps>

                    {step === 1 ? (
                        <RegisterCreateAccount onComplete={moveToNextStep} />
                    ) : null}

                    {step === 2 ? (
                        <RegisterSetupProfile onComplete={moveToNextStep} />
                    ) : null}

                    {step === 3 ? <RegisterUploadFirstArtwork /> : null}
                </Card.Body>
                <hr />
                <Card.Body>
                    <p>
                        Already have an account? &nbsp;
                        <LinkContainer to="/login">
                            <a href="/login">Login to your account!</a>
                        </LinkContainer>
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default RegisterPage;
