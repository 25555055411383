import { createSlice } from "@reduxjs/toolkit";
import { User } from "../profile/User";

interface AuthState {
    isLoggedIn: boolean;
    loggedInUser: User | undefined;
    accessToken: string | undefined;
    refreshToken: string | undefined;
}

const initialState: AuthState = {
    isLoggedIn: false,
    loggedInUser: undefined,
    accessToken: undefined,
    refreshToken: undefined,
};

export const authSlice = createSlice({
    name: "auth",
    initialState: () => {
        const localState = localStorage.getItem("_huez_auth_state");
        if (localState) {
            try {
                const parsedLocalState = JSON.parse(localState);
                return parsedLocalState;
            } catch {
                return initialState;
            }
        }
        return initialState;
    },
    reducers: {
        setCredentials: (state, authPayload) => {
            console.log(authPayload);
            state.isLoggedIn = true;
            state.loggedInUser = {
                lastName: authPayload.payload.user.firstName,
                firstName: authPayload.payload.user.lastName,
                email: authPayload.payload.user.email,
            };
            state.accessToken = authPayload.payload.accessToken;
            state.refreshToken = authPayload.payload.refreshToken;

            localStorage.setItem("_huez_auth_state", JSON.stringify(state));
        },
        clearCredentials: (state) => {
            state.isLoggedIn = false;
            localStorage.removeItem("_huez_auth_state");
        },
    },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;
