import React from "react";
import PageTitle from "../../components/atoms/PageTitle/PageTitle";

function OrdersList() {
    return (
        <>
            <PageTitle title="Orders"></PageTitle>
        </>
    );
}

export default OrdersList;
