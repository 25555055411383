import React from "react";
import { Button, Form } from "react-bootstrap";
import InputField from "../../components/atoms/InputField/InputField";
import WizardStepProps from "./WizardStepsProps";

function RegisterSetupProfile(props: WizardStepProps) {
    return (
        <>
            <Form noValidate onSubmit={() => props.onComplete()}>
                <InputField
                    required
                    placeholder="Profile Name"
                    helpText="This the name that will be visible in the Huez App"
                ></InputField>

                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Profile picture</Form.Label>
                    <Form.Control className="rounded-5" type="file" />
                </Form.Group>

                <div className="d-grid">
                    <Button
                        className="rounded-5"
                        variant="primary"
                        type="submit"
                    >
                        Setup profile
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default RegisterSetupProfile;
