import React from "react";
import { Badge, Card } from "react-bootstrap";
import { Artwork, ArtworkImage } from "../../../models/Artwork";

function ArtworkCard({ artwork }: { artwork: Artwork }) {
    return (
        <Card key={artwork.id}>
            <Badge
                bg="success"
                style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                }}
            >
                {artwork.status}
            </Badge>
            <Card.Img
                src={
                    artwork.images.find((image: ArtworkImage) => image.isMain)
                        ?.url
                }
                style={{ height: 350 }}
            />
            <Card.ImgOverlay
                className="text-dark"
                style={{
                    height: 150,
                    position: "absolute",
                    top: "auto",
                    bottom: 0,
                    background: "rgba(255,255,255,0.75)",
                }}
            >
                <Card.Title>{artwork.name}</Card.Title>
                <Card.Text>
                    {artwork.artStyles.map((style: any) => (
                        <Badge
                            pill
                            bg="dark"
                            className="text-light fw-normal me-2"
                        >
                            {style.name}
                        </Badge>
                    ))}
                </Card.Text>
                <a
                    href="/#"
                    className="stretched-link"
                    style={{ textDecoration: "none" }}
                    onClick={(e) => e.preventDefault()}
                >
                    &nbsp;
                </a>
            </Card.ImgOverlay>
        </Card>
    );
}

export default ArtworkCard;
