import React from "react";
import { LucideIcon } from "lucide-react";

function SectionTitle(props: { title: string; icon?: LucideIcon }) {
    return (
        <>
            <h2 className="mt-4 mb-2">
                {props.icon ? <props.icon size={40} strokeWidth={1} /> : null}
                {props.title}
            </h2>
        </>
    );
}

export default SectionTitle;
