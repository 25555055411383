import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./pages/login/AuthSlice";
import { huezApi } from "./api";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [huezApi.reducerPath]: huezApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(huezApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
